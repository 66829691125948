import axios from 'axios'
import store from '../store/index.js'
import router from '@/router/index.js'
let httpUrl = '';
if(process.env.NODE_ENV === 'development'){
    //开发环境
    httpUrl = '/api'
}else{
    //正式环境
    httpUrl = 'https://api.tlbsel.com'
    // httpUrl = 'https://qtv.tlbsel.com'
}
let instance = axios.create({
	baseURL:httpUrl,
	timeout:'5000',
	withCredentials:'true'
})


//也可以在这里写一个请求时候的效果比如转圈，放在请求拦截器和相应拦截器，根据状态改变请求的效果。

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// instance.defaults.transformRequest = data => qs.stringify(data)

//请求拦截器
instance.interceptors.request.use(config=>{
    let token = window.localStorage.getItem('tltoken')
    let userId =  store.state.userid;
    token && (config.headers.token = token)
    userId && (config.headers.userId = userId)
    //这里注意一定要return出去这个config
    return config
},error=>{
    //错误处理
    console.log(error,'拦截器报错')
	return Promise.reject(error)
})
//相应拦截器
instance.interceptors.response.use(res=>{
    //对数据做点什么
    if(res.data.code == '500404'){
            router.push({
                path:'/login'
            })
    }else{
        return Promise.resolve(res.data)
    }
},error=>{
    console.log(error.response,'响应器error报错码参数')
	//错误处理
	// let { response } = error;
    //通过状态码判断是否没有token，或者登录的token过期了，或者页面报错404，或者报错500
    // if(response.code == 404){

    // }else{
    //     //这边处理未联网的逻辑
    //     if(!window.navigator.onLine){
    //         //断网处理，可以跳转到断网页面（单独写一个断网处理的页面）
    //         return  
    //     }
        return Promise.reject(error)
    // }
})

export default {
    instance,
    httpUrl
}