import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
import comHttp from '@/common/request'
export default new Vuex.Store({
  state: {
    token:false,//用户登陆成功改为true
    userid:'',
    user:{},//存储用户登录的信息
    routePath:[],//路由存储
    videoId:''
  },
  mutations: {
    //存储播放id
    getVideo(state,id){
      state.videoId = id
    },
    //初始化用户信息
    loginUpdata(state){
      let token = window.localStorage.getItem('tltoken');
      if(!token) return
      let user =  window.localStorage.getItem('user')
      user = JSON.parse(user);
      state.userid = user.id;
      state.user = user;
      state.token = user.token;
    },
    //登录
    login(state,user){
        //保存登录状态
        state.user = user;
        state.token = user.token;
        state.userid = user.id;
        //存储到本地
        window.localStorage.setItem('user',JSON.stringify(user))
        window.localStorage.setItem('tltoken',user.token)
    },
    //退出登录
    logout(state){
        state.user = {};
        state.token = false;
        //清除本地存储的数据
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('tltoken')
    },
    //替换user用户信息
    replaceUserInfor(state,userObj){
      state.user.headPic = userObj.headPic;
      state.user.nickname = userObj.nickname;
    },
    //替换用户信息user
    splitUser(state,user){
      state.user = user;
      //存储到本地
      window.localStorage.setItem('user',JSON.stringify(user))
    },
    playDetails(state,user){
      state.user = user;
    },
    //不是新用户情况下刷新登录信息
    userInfor(state){
      if(state.user){
          let params = {phone:state.user.phone};
          axios({
            url:comHttp.httpUrl+'/tl_en_school/pc/user/getUserByPhone',
            method:'get',
            headers:{
              userId:state.userid,
              token:state.token
            },
            params
          }).then(res=>{
              if(res.data.code == 200){
                // state.token = token;
                // state.userid = myUserInfor.id;
                // window.localStorage.setItem('user',JSON.stringify(res.data.result.domain))
                // state.user = res.data.result.domain;
                // console.log(state.userid,'打印用户存储信息')
              }else{
                state.user = {};
                state.userid = '';
                state.token = false;
                //清除本地存储的数据
                window.localStorage.removeItem('user')
                window.localStorage.removeItem('tltoken')
              }
          })
      }
    },
    //监听路由方法
    getHostoyPath(state,url){
      let obj = {url};
        state.routePath.push(obj)
    },
    //清除路由的方法
    removePath(state){
      state.routePath = [];
    }
  },
  actions: {

  },
  modules: {
  }
})
