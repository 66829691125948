import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import less from 'less'
Vue.use(less);
import { Pagination,Breadcrumb,BreadcrumbItem,Steps,Step,Message,MessageBox } from 'element-ui'
Vue.use(Pagination)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Steps)
Vue.use(Step)
Vue.component(MessageBox)
Vue.component(Message)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
//定义一个全局过滤器
Vue.filter('toTime',function(value) {
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  return y + '-' + MM + '-' + d ;
})
Vue.filter('toYearData',function(value) {
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  // let h = date.getHours();
  // h = h < 10 ? ('0' + h) : h;
  return y + '年' + MM + '月' + d + '日';
})
//转换为分钟和秒的格式
Vue.filter('toMinute',function(value) {
  let date = new Date(value);
  let m = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return m + ':' + s;
})
//转换为年月日和小时的格式
Vue.filter('toHours',function(value) {
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let m = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return y+'-'+MM+'-'+d+' '+h+':'+m+':'+s;
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
