import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: () => import('@/views/index.vue') },
  { path: '/login', name: 'Login', component: () => import('@/views/login/login.vue') },
  { path: '/register', name: 'Register', component: () => import('@/views/login/register.vue') },
  { path: '/courseList', component: () => import('@/views/course/courseList.vue') },
  { path: '/about', name: 'About', component: () => import('@/views/aboutus/about.vue') },
  { path: '/forgetcode', name: 'ForgetCode', component: () => import('@/views/login/forgetCode.vue') },
  { path: '/playdetails', name: 'playdetails', component: () => import('@/views/course/courseDetail.vue') },
  { path: '/payPage', name: 'payPage', component: () => import('@/views/paymentInfor/payPage.vue') },
  { path: '/paymentInfor', name: 'paymentInfor', component: () => import('@/views/paymentInfor/paymentDetail.vue') },
  { path: '/setting', name: 'setting', component: () => import('@/views/setting/setting.vue') },
  { path: '/news', name: 'news', component: () => import('@/views/news/news.vue') },
  { path: '/newsDetail', name: 'newsDetail', component: () => import('@/views/news/newDetail.vue') },
  { path:'*',redirect:{path:'/'} }
]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//全局导航钩子
// router.beforeEach((to,form,next)=>{
//   let user = window.sessionStorage.getItem('user')
//   let token = window.sessionStorage.getItem('token')
//   if(token){//存在token
//     //这个判断里面主要是进行一些登录之后的一些判断********
//     //为了防止重复登录进行一次判断
//     if(to.path === '/login'){
//       Vue.prototype.$message.error('请不要重复登录')
//       return next({name:form.name ? form.name:'index'})
//     }
//     next()
//   }else{
//     //不存在token的情况下进入login页面进行登录
//     if(to.path === '/login'){
//       return next()
//     }
//     Vue.prototype.$message.error('请先登录')
//     return next({path:'/login'})
//   }
// })

export default router
